<script lang="ts" setup></script>
<template>
  <div class="honor">
    <h3><span>团队荣誉</span></h3>
    <van-image fit="cover" src="http://tanchongkeji.com/honor3.png" lazy-load />
    <lazy-component>
      <van-image
        fit="cover"
        src="http://tanchongkeji.com/honor1.jpg"
        lazy-load
      />
      <!-- <div class="img-grid">
        <div v-for="(img, index) in imgList" :key="index">
          <div class="img-item">
            <van-image fit="cover" :src="img" lazy-load />
          </div>
        </div>
                      </div> -->
    </lazy-component>
  </div>
</template>
<style scoped>
.honor {
  padding-left: 0.5rem;
  padding-right: 0.5rem;
}

.honor h3 span {
  border-bottom: #42b883 0.15rem solid;
}

.honor h3 {
  text-align: left;
  color: #213547;
}

.honor .img-grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 0.5rem;
}

.img-grid .img-item {
  font-size: 0.8rem;
  font-weight: 700;
  color: #ba1825;
}
</style>
